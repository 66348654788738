<template>
  <div class="card card-custom">
    <div class="card-body">
      <!--begin::Row-->
      <div class="row">
        <div class="col-xl-2"></div>
        <div class="col-xl-7">
          <!--begin::Row-->
          <div class="row">
            <label class="col-3"></label>
            <div class="col-9">
              <h6 class="text-dark font-weight-bold mb-10">Change Your Password:</h6>
            </div>
          </div>
          <!--end::Row-->
          <!--begin::Group-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">Current Password</label>
            <div class="col-9">
              <input
                class="form-control form-control-lg form-control-solid mb-1"
                type="password"
                v-model="currentPassword"
              />
            </div>
          </div>
          <!--end::Group-->
          <!--begin::Group-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">New Password</label>
            <div class="col-9">
              <input class="form-control form-control-lg form-control-solid" type="password" v-model="newPassword" />
              <span class="form-text text-muted">Length must be above 6 character</span>
            </div>
          </div>
          <!--end::Group-->
          <!--begin::Group-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">Verify Password</label>
            <div class="col-9">
              <input
                class="form-control form-control-lg form-control-solid"
                type="password"
                v-model="confirmPassword"
              />
              <span class="form-text text-muted">Length must be above 6 character</span>
            </div>
          </div>
          <!--end::Group-->
        </div>
      </div>
      <!--end::Row-->
    </div>
    <!--end::Body-->
    <!--begin::Footer-->
    <div class="card-footer">
      <div class="row">
        <div class="col-xl-2"></div>
        <div class="col-xl-7">
          <div class="row">
            <div class="col-3"></div>
            <div class="col-9">
              <a href="#" class="btn btn-light-primary font-weight-bold" @click="submitChange">Submit</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { changePassword } from "@/api/auth.api";
import Swal from "sweetalert2";
export default {
  setup() {
    const currentPassword = ref("");
    const newPassword = ref("");
    const confirmPassword = ref("");

    const submitChange = () => {
      Swal.fire({
        title: "Change password?",
        text: "Are you sure you want to change your password",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!"
      }).then(async result => {
        if (result.isConfirmed) {
          const payload = {
            current_password: currentPassword.value,
            password: newPassword.value,
            confirm_password: confirmPassword.value
          };

          const response = await changePassword(payload);
          if (response.status === 200) {
            Swal.fire("Success", "Password have been updated", "success");
            currentPassword.value = "";
            newPassword.value = "";
            confirmPassword.value = "";
          }
        }
      });
    };

    return {
      currentPassword,
      newPassword,
      confirmPassword,

      submitChange
    };
  }
};
</script>
